<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("DateFrom") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateFrom"
            class="vx-col  w-full"
            name="from"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("DateTo") }}</label>
          <datepicker
            placeholder="from"
            v-model="search.DateTo"
            class="vx-col  w-full"
            name="from"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("doctors") }}</label>
          <v-select
            label="FullName"
            class="w-full"
            v-model="search.DoctorID"
            :options="doctors"
            :reduce="ID => ID.ID"
          />
        </div>
        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("Patient") }}</label>

          <v-select
            label="FullName"
            class="w-full"
            v-model="search.PatientID"
            :options="patients"
            :reduce="ID => ID.ID"
          />
        </div>

        <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-5">
          <label class="text-sm opacity-75">{{ $t("Status") }}</label>
          <v-select
            label="Name"
            class="w-full"
            v-model="search.StatusID"
            :options="status"
            :reduce="ID => ID.ID"
          />
        </div>

        <div class="vx-col  w-full">
          <div class="vx-row">
            <vs-button
              style="margin:15px"
              class="my-6"
              @click="ReservationSearch"
              v-scroll-to="'#Scroll'"
              >{{ $t("Search") }}</vs-button
            >
            <vs-button
              style="margin-left:15px"
              class="my-6"
              @click="resetData"
              >{{ $t("Reset") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>

    <vs-table
      id="Scroll"
      ref="table"
      multiple
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="Reservations"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <!-- <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus"
          @click="addNewData"
          >{{ $t("AddNew") }}</vs-button
        > -->

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                Reservations.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : Reservations.length
              }}
              of {{ Reservations.length }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="Doctor">{{ $t("doctors") }}</vs-th>
        <vs-th sort-key="Day">{{ $t("Patient") }}</vs-th>
        <vs-th sort-key="TimeFrom">{{ $t("TOTALPRICE") }}</vs-th>
        <vs-th sort-key="TimeFrom">{{ $t("Date") }}</vs-th>
        <vs-th sort-key="TimeTo">{{ $t("Status") }}</vs-th>

        <vs-th>{{ $t("Action") }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p
                v-if="tr.DoctorSession"
                class="product-name font-medium truncate"
              >
                {{ tr.DoctorSession.Doctor.FullName }}
              </p>
            </vs-td>

            <vs-td>
              <p v-if="tr.Patient" class="product-name font-medium truncate">
                {{ tr.Patient.FullName }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.Total }}</p>
            </vs-td>
            <vs-td>
              <p
                v-if="tr.DoctorSession"
                class="product-name font-medium truncate"
              >
                {{ tr.DoctorSession.DayUTC.split("T")[0] }}
              </p>
            </vs-td>
            <vs-td>
              <p
                v-if="tr.Status"
                class="product-name font-medium truncate"
              >
                {{ tr.Status.Name }}
              </p>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                @click.stop="editData(tr.ID)"
                icon="EditIcon"
                class="m-2 "
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
              />
              <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click="openConfirm(tr)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
// import DataViewSidebar from "./DataViewSidebar.vue";
import moduleReservation from "@/store/reservation/reservation/moduleReservation.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import Datepicker from "vuejs-datepicker";
import moduleStatus from "@/store/settings/status/moduleStatus.js";
import modulePatient from "@/store/Patient/modulePatient.js";

export default {
  components: {
    // DataViewSidebar,
    Datepicker
  },
  data() {
    return {
      selected: [],
      search: {},
      itemsPerPage: 10,
      isMounted: false,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Reservations() {
      return this.$store.state.ReservationList.Reservations;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    status() {
      return this.$store.state.StatusList.status;
    },
    patients() {
      return this.$store.state.patientList.patients;
    }
  },

  methods: {
    resetData() {
      this.search = {};
    },
    ReservationSearch() {
      this.$store.dispatch("ReservationList/SearchReservation", this.search);
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },

    deleteData(data) {
      this.$store
        .dispatch("ReservationList/DeleteReservation", data)
        .then(() => {
          window.showDeleteSuccess();
        })
        .catch(err => {
          console.error(err);
        });
    },

    editData(ID) {
      this.$router.push({ name: "Reservation-edit", params: { ID: ID } });
    },

    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$t("Confirm"),
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel")
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      // this.$vs.notify({
      //   color: "danger",
      //   title: "Deleted record",
      //   text: "The selected record was successfully deleted"
      // });
    }
  },

  created() {
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }

    if (!moduleReservation.isRegistered) {
      this.$store.registerModule("ReservationList", moduleReservation);
      moduleReservation.isRegistered = true;
    }
    if (!moduleStatus.isRegistered) {
      this.$store.registerModule(
        "StatusList",
        moduleStatus
      );
      moduleStatus.isRegistered = true;
    }

    this.$store.dispatch("StatusList/GetAllStatus");
    this.$store.dispatch("DoctorList/GetAllDoctors", {});
    this.$store.dispatch("patientList/SearchPatients", {});

    this.$store.dispatch("ReservationList/SearchReservation", {});
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
